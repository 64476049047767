import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout/Layout"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"

import HeaderImage from "../../assets/img/new/tlo-serch-01.webp"

import "../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const CookiesPolicy = () => {
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("de")
  }, [])
  return (
    <Layout
      seo={{
        title: "Informieren Sie sich über die Cookie-Richtlinie auf der WeNet SEO Audit Website",
        description:
          "Lesen Sie auf der SEO-Audit-Website ausführliche Informationen über Cookies: was sie sind, wofür sie verwendet werden und wer Zugriff auf sie hat.",
        hreflangs: {
          pl: "polityka-cookies/",
          en: "en/cookies-policy/",
          de: "de/cookies-politik/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/de/cookies-politik/",
                name: t("link-text-cookies-policy"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-cookies-policy"),
                text: t("link-text-cookies-policy"),
              },
            ]}
          />
        </div>
        <div style={{ padding: "0 15px" }}>
          <h1 style={{ marginTop: "40px" }}>Cookies Politik</h1>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
      <section className="standard-section regulations-section cookies-policy">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <p>
                Wir verwenden Cookies, um die Effizienz zu steigern und den Nutzern immer bessere Funktionen der{" "}
                <Link to="/de">audytseo.wenet.pl</Link> -Website (im Folgenden als Website bezeichnet) und
                individuellere Inhalte anbieten zu können. Um die Transparenz der Abläufe zu gewährleisten, wird diese
                Cookie-Politik angenommen, die detaillierte Regeln für die Verwendung von Cookies durch die Website
                festlegt.
              </p>
              <p>
                Daten mittels Cookies werden von WeNet Group S.A. gesammelt. Adresse: 14A Postępu Straße 02-676
                Warschau, NIP Nr.: 7010046065, REGON Nr.: 140756502 und WeNet Sp. z o.o. gesammelt. Adresse: 14A Postępu
                Straße 02-676 Warschau, NIP Nr.: 5210125377, REGON Nr.: 012110943. Wenn festgestellt wird, dass die in
                den Cookies enthaltenen Daten personenbezogene Daten sind, gilt WeNet Group S.A. und WeNet Sp. z o.o.
                als Datenverwalter und der Nutzer hat Anspruch auf alle Rechte, die in der Datenschutzrichtlinie
                enthalten sind, die Teil der <Link to="/de/satzung"> Bestimmungen</Link> ist.
              </p>
              <p>
                Ein Nutzer ist jede betroffene Person, die unsere Website und andere damit verbundene Websites,
                Mitteilungen und Dienste nutzt (im Folgenden als Nutzer bezeichnet).
              </p>
              <h2>I. COOKIES</h2>
              <p>
                Cookies ("Cookies") sind Codefragmente, die Textdateien sind, die den HTTP-Anfragen an unseren Server
                entsprechen. Diese Dateien werden von jedem Browser gespeichert, mit dem der Nutzer unsere Website
                besucht. Dank der in den Cookies enthaltenen Informationen wissen wir, wie die Nutzer unsere Website
                verwenden.
              </p>
              <p>
                Wir verwenden Cookies, um sicherzustellen, dass Ihr Besuch auf unserer Website optimiert wird, um Ihnen
                einen schnelleren und einfacheren Zugriff auf Informationen zu ermöglichen und um Ihnen immer bessere
                Website-Funktionen und Inhalte zu bieten, die für Sie relevanter sind.
              </p>
              <p>
                Das Speichern von Informationen oder der Zugriff darauf führt nicht zu einer Änderung der Konfiguration
                des Geräts des Nutzers oder der darauf installierten Software.
              </p>
              <p>
                Die in Cookies enthaltenen Informationen gelten nur in Verbindung mit anderen über den Nutzer
                verfügbaren personenbezogenen Daten als personenbezogene Daten. Wir bemühen uns, mit Hilfe von Cookies
                nur anonyme Daten über den Nutzer zu sammeln, die dem Zweck dienen, die Nutzung des Portals zu
                erleichtern und die Belästigung durch Werbung zu minimieren.&nbsp;
              </p>
              <h2>II. ARTEN UND ZWECKE DER VERWENDUNG VON COOKIES</h2>
              <p>
                Wir verwenden Cookies und andere ähnliche Technologien, um den Nutzer während seines Besuchs auf unserer
                Website zu erkennen, sich seine Präferenzen zu merken und geeignete, maßgeschneiderte Inhalte
                anzuzeigen. Cookies machen Ihre Interaktionen mit uns schneller und effektiver. Darüber hinaus
                ermöglichen es uns Cookies, dem Nutzer maßgeschneiderte Inhalte zu präsentieren, ohne dass Umfragen
                erforderlich sind.
              </p>
              <p>Es gibt zwei Arten von Cookies, die auf der Website verwendet werden:</p>
              <ul>
                <li>
                  <p>
                    <strong>Sitzungs Cookies</strong> - dies sind temporäre Dateien, die auf dem Gerät des Nutzers
                    verbleiben, bis der Nutzer die Website verlässt oder den Webbrowser ausschaltet. In diesem Fall
                    werden die in Session-Cookies gespeicherten Informationen automatisch gelöscht.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Permanente (dauerhafte) Cookies</strong> - das sind Dateien, die auf dem Gerät des Nutzers
                    für den in den Cookie-Parametern angegebenen Zeitraum oder bis zu ihrer manuellen Löschung
                    verbleiben.
                  </p>
                </li>
              </ul>
              <p>
                Im Bereich des Dienstes werden auch andere verfügbare Technologien verwendet, die es ermöglichen,
                Informationen im Browser in entsprechenden Datenspeichern (Sitzungsspeicher, lokaler Speicher) zu
                speichern, und die auch in den Codefragmenten von Analysetools anderer Anbieter enthalten sind, die die
                Speicherung von Cookies in den Domänen dieser Dienste ermöglichen.
              </p>
              <p>
                Im Folgenden werden die verschiedenen Kategorien von Cookies beschrieben, die auf unserer Website
                verwendet werden:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Technische (essentielle) Cookies</strong> - diese sind notwendig, damit die Website
                    ordnungsgemäß funktioniert. Sie beziehen sich auf die Funktionalität unserer Websites und
                    ermöglichen es uns, die über unsere Websites angebotenen Dienste zu verbessern, indem sie
                    beispielsweise die Übertragung von Informationen zwischen den einzelnen Seiten unserer Website
                    ermöglichen, um die erneute Eingabe von Informationen zu vermeiden, oder indem sie Ihre Präferenzen
                    erkennen, wenn Sie zu einer Seite unserer Website zurückkehren. Wir verwenden sie, um:
                  </p>
                  <ul>
                    <li>
                      <p>Gewährleistung der Sicherheit</p>
                    </li>
                    <li>
                      <p>Ihre Sitzung aufrechtzuerhalten, wenn Sie Ihr Kundenkonto besuchen und sich dort anmelden</p>
                    </li>
                    <li>
                      <p>
                        um sicherzustellen, dass die Website je nach Gerät, das Sie verwenden, angemessen angezeigt
                        wird,
                      </p>
                    </li>
                    <li>
                      <p>Anpassung unserer Dienstleistungen an Ihre Wünsche</p>
                    </li>
                    <li>
                      <p>Sie sich für die Anzeige bestimmter Inhalte angemeldet haben.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Funktionale Cookies</strong> - speichern die vom Nutzer getroffenen Entscheidungen und
                    bieten so einen besser zugeschnittenen Service.&nbsp;Wir verwenden sie, um:
                  </p>
                  <ul>
                    <li>
                      <p>
                        die Funktionalität der Websites zu unterstützen; ohne sie sollte die Website zwar korrekt
                        funktionieren, wird aber nicht auf die Präferenzen des Nutzers zugeschnitten sein
                      </p>
                    </li>
                    <li>
                      <p>
                        um ein hohes Maß an Funktionalität der Websites zu gewährleisten; ohne sie kann die
                        Funktionalität der Website eingeschränkt sein, aber ihr Fehlen sollte nicht verhindern, dass die
                        Website überhaupt genutzt werden kann;
                      </p>
                    </li>
                    <li>
                      <p>
                        die meisten Funktionen der Websites zu nutzen; eine Blockierung führt dazu, dass ausgewählte
                        Funktionen nicht richtig funktionieren.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Analytische Cookies</strong> - ermöglichen es uns, die Anzahl der Nutzer, die unseren Dienst
                    besuchen, zu erkennen und zu zählen, um zu sehen, wie die Besucher durch den Dienst navigieren, wenn
                    sie ihn nutzen, und um aufzuzeichnen, welche Inhalte sie ansehen und wofür sie sich interessieren.
                    Auf diese Weise können wir feststellen, wie oft Seiten und Anzeigen besucht werden und welche
                    Bereiche unseres Dienstes am beliebtesten sind. Dies hilft uns, die von uns angebotenen Dienste zu
                    verbessern, indem wir sicherstellen, dass unsere Nutzer die Informationen finden, die sie suchen.
                    Sie sind auch notwendig, um unseren vertrauenswürdigen Partnern Rechnungen auszustellen, um die
                    Effektivität unserer Marketingmaßnahmen zu messen, ohne persönliche Daten zu identifizieren. Wir
                    verwenden sie für:
                  </p>
                  <ul>
                    <li>
                      <p>
                        um Statistiken über den Website-Verkehr zu prüfen und die Quelle des Verkehrs zu ermitteln
                        (Umleitungsrichtungen),&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        Erkennung verschiedener Arten von Missbrauch, z. B. künstlicher Webverkehr (Bots), DDoS-Angriffe
                      </p>
                    </li>
                    <li>
                      <p>Begrenzung unerwünschter Marketingaktivitäten</p>
                    </li>
                    <li>
                      <p>
                        Messung der Wirksamkeit von Marketingaktivitäten, die im Namen der Website durchgeführt werden,
                        z. B. im Werbenetzwerk von Google, in Partnerprogrammen, auf externen Websites,
                      </p>
                    </li>
                    <li>
                      <p>
                        zur Abrechnung von Werbedienstleistungen mit vertrauenswürdigen Partnern auf der Grundlage von
                        Nutzeraktivitäten, z. B. Klicks.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Marketing-Cookies</strong> - werden verwendet, wenn der Nutzer zustimmt und nur dann, wenn
                    die von unserem Dienst verwendeten Funktionen und Werbeformen dies erfordern. Wir verwenden sie, um:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Anzeige von Werbung, die für einen bestimmten Nutzer relevant und interessant ist. Sie betreffen
                        die Erstellung von Profilen für Werbung, die sowohl auf externen Websites als auch auf unserer
                        Website angezeigt wird, entsprechend den Präferenzen des Nutzers in Bezug auf die Auswahl von
                        Waren, basierend auf Daten, die sich im Besitz der Website befinden, einschließlich des
                        Verhaltens des Nutzers auf der Website.&nbsp;
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Durch den Zugriff auf die Website kann der Nutzer der Verwendung von Analyse- und Marketing-Cookies
                nicht zustimmen. In diesem Fall sind wir nicht in der Lage, detaillierte Statistiken zu führen und
                unsere Werbung auf der Grundlage der in unserem Dienst gesammelten Informationen anzupassen. Sie werden
                jedoch weiterhin
              </p>
              <p>Anzeigen allgemeiner Art sowohl auf der Servus-Website als auch auf anderen Websites angezeigt.</p>
              <p>
                Wir verwenden von Google und FB bereitgestellte Technologien zur Schaltung von Anzeigen im Web sowie in
                Bezug auf andere Tools. Bitte beachten Sie, ob Sie der Verwendung dieser Tools zustimmen, einschließlich
                der Profilerstellung von Anzeigen im Web und auf FB unter Verwendung von Technologien, die von Google
                verwendet werden, was Sie{" "}
                <a
                  href="https://policies.google.com/privacy?hl=pl#infochoices"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  hier
                </a>{" "}
                tun können, und von FB, was Sie{" "}
                <a href="https://pl-pl.facebook.com/privacy/explanation/" target="_blank" rel="nofollow noreferrer">
                  hier
                </a>{" "}
                tun können.
              </p>
              <p>
                Genauere Informationen über die von uns verwendeten Cookies, die Dauer ihrer Speicherung und die
                aktuelle Liste unserer vertrauenswürdigen Partner, deren Dienste wir nutzen oder deren Technologien wir
                in unseren Dienst einbinden, finden Sie in der Tabelle:{" "}
                <Link to="/de/cookies-politik/tabelle-cookies">Tabelle Cookies</Link>
              </p>
              <h2>III. DEAKTIVIERUNG VON COOKIES</h2>
              <p>
                Webbrowser lassen die Speicherung von Cookies standardmäßig zu. Wenn Sie keine Cookies erhalten möchten,
                können Sie die Einstellungen Ihres Browsers auf dem Computer oder einem anderen Gerät, das Sie für den
                Zugriff auf unsere Website verwenden, ändern. Wenn Sie unseren Dienst weiterhin nutzen, ohne Ihre
                Einstellungen zu ändern, erklären Sie sich damit einverstanden, dass technische Cookies auf unserem
                Dienst verwendet werden, die, wie wir angegeben haben, für das ordnungsgemäße Funktionieren unserer
                Dienstseite erforderlich sind.
              </p>
              <p>
                Die meisten Webbrowser verfügen über eine Funktion, die es dem Nutzer ermöglicht, Cookies einzusehen und
                zu löschen, einschließlich der Cookies auf unserer Website. Es wird jedoch darauf hingewiesen, dass die
                Unfähigkeit unserer Website, Cookies zu speichern oder zu lesen, die vollständige und korrekte Nutzung
                unserer Website verhindern kann.
              </p>
              <p>
                Wenn Sie mit verschiedenen Geräten auf unsere Website zugreifen und darin surfen (z. B. Computer,
                Smartphone, Tablet), sollten Sie sicherstellen, dass jeder Browser auf jedem Gerät an Ihre
                Cookie-Einstellungen angepasst ist.
              </p>
              <p>
                Wie Sie Cookies deaktivieren können, hängt von dem Browser ab, den der Nutzer verwendet. Im Folgenden
                wird beschrieben, wie der Nutzer Cookies in Google Chrome, Firefox, Safari, Opera und Internet Explorer
                deaktivieren kann.
              </p>
              <h3>Cookies in Google Chrome deaktivieren</h3>
              <ul>
                <li>
                  <p>
                    Wählen Sie in der oberen rechten Ecke neben der Adressleiste des Google Chrome-Browsers die drei
                    vertikalen Punkte
                  </p>
                </li>
                <li>
                  <p>Klicken Sie im Abschnitt Sicherheitsdatenschutz auf Inhalt.</p>
                </li>
                <li>
                  <p>Im Abschnitt Cookies und Seitendaten können Sie die folgenden Cookie-Einstellungen ändern:</p>
                  <ul>
                    <li>
                      <p>Alle Cookies blockieren</p>
                    </li>
                    <li>
                      <p>Cookies von Drittanbietern blockieren</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Weitere Informationen zu den Einstellungen des Google Chrome-Browsers finden Sie hier:&nbsp;{" "}
                <a href="https://support.google.com/chrome/#topic=7438008" target="_blank" rel="nofollow noreferrer">
                  https://support.google.com/chrome/#topic=7438008
                </a>
                &nbsp;
              </p>
              <h3>Cookies in Firefox deaktivieren</h3>
              <ul>
                <li>
                  <p>
                    Klicken Sie in der oberen rechten Ecke des Firefox-Browserfensters auf das Menü Öffnen (drei
                    horizontale Striche) und wählen Sie Einstellungen.
                  </p>
                </li>
                <li>
                  <p>
                    Wählen Sie dann im Abschnitt Datenschutz und Sicherheit die Registerkarte Cookies und Website-Daten.
                  </p>
                </li>
                <li>
                  <p>
                    Wählen Sie die entsprechende Sicherheitsstufe oder konfigurieren Sie Ihre eigene, indem Sie
                    auswählen, welche Elemente und Skripte blockiert werden sollen - Cookies:
                  </p>
                  <ul>
                    <li>
                      <p>Alle von externen Websites</p>
                    </li>
                    <li>
                      <p>Alle</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Weitere Informationen zu den Einstellungen des Firefox-Browsers:&nbsp;
                <a
                  href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek
                </a>
                &nbsp;
              </p>
              <h3>Cookies im Safari-Browser deaktivieren</h3>
              <ul>
                <li>
                  <p>
                    Wählen Sie den Menübefehl Safari &gt; Einstellungen, klicken Sie auf Datenschutz und führen Sie dann
                    einen der folgenden Schritte aus:
                  </p>
                  <ul>
                    <li>
                      <p>Alle Cookies blockieren</p>
                    </li>
                    <li>
                      <p>Verhindern Sie die Verfolgung außerhalb des Unternehmens</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Weitere Informationen zu den Einstellungen des Safari-Browsers finden Sie hier:{" "}
                <a
                  href="https://support.apple.com/kb/PH19214?viewlocale=pl_PL&amp;locale=pl_PL"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  https://support.apple.com/kb/PH19214?viewlocale=pl_PL&amp;locale=pl_PL
                </a>
                &nbsp;
              </p>
              <h3>Cookies im Opera-Browser deaktivieren</h3>
              <ul>
                <li>
                  <p>
                    Wählen Sie in der oberen rechten Ecke neben der Adressleiste des Opera-Browsers die drei
                    horizontalen Linien
                  </p>
                </li>
                <li>
                  <p>Klicken Sie auf Gehe zu den vollständigen Browsereinstellungen</p>
                </li>
                <li>
                  <p>Klicken Sie im Abschnitt Datenschutz auf Cookies und andere Website-Daten.</p>
                </li>
                <li>
                  <p>Sie können die folgenden Cookie-Einstellungen ändern:</p>
                  <ul>
                    <li>
                      <p>Alle Cookies blockieren</p>
                    </li>
                    <li>
                      <p>Cookies von Drittanbietern blockieren</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Weitere Informationen zu den Einstellungen des Opera-Browsers finden Sie hier:{" "}
                <a href="http://help.opera.com/Windows/12.10/pl/cookies.html" target="_blank" rel="nofollow noreferrer">
                  http://help.opera.com/Windows/12.10/pl/cookies.html
                </a>
                &nbsp;
              </p>
              <h3>Deaktivieren von Cookies im Edge-Browser</h3>
              <ul>
                <li>
                  <p>
                    Wählen Sie die drei horizontalen Punkte in der oberen rechten Ecke der Adressleiste des
                    Edge-Browsers
                  </p>
                </li>
                <li>
                  <p>Klicken Sie auf Einstellungen</p>
                </li>
                <li>
                  <p>
                    Klicken Sie unter Cookies und Website-Berechtigungen auf Verwalten und Löschen von Cookies und
                    Website-Daten
                  </p>
                </li>
                <li>
                  <p>Sie können die folgenden Cookie-Einstellungen ändern:</p>
                  <ul>
                    <li>
                      <p>Block</p>
                    </li>
                    <li>
                      <p>Cookies von Drittanbietern blockieren</p>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Weitere Informationen zu den Einstellungen des Edge-Browsers finden Sie hier:{" "}
                <a href="https://support.microsoft.com/pl-pl/microsoft-edge" target="_blank" rel="nofollow noreferrer">
                  https://support.microsoft.com/pl-pl/microsoft-edge
                </a>
                &nbsp;
              </p>
              <h2>IV. KONTAKTINFORMATIONEN</h2>
              <p>
                Wenn der Nutzer Zweifel bezüglich dieser Cookies-Politik hat oder wenn er Informationen über diese
                Cookies-Politik erhalten möchte, kann er sich an den Administratoren wenden, und zwar über den
                Datenschutzbeauftragten (Wojciech Zaręba), der unter der E-Mail-Adresse{" "}
                <a href="mailto:iod@wenet.pl">iod@wenet.pl</a> oder per Post an die Adresse des Administrators
                erreichbar ist.
              </p>
              <button
                type="button"
                onClick={() => {
                  const _hsp = (window._hsp = window._hsp || [])
                  _hsp.push(["showBanner"])
                }}
                className="btn btn-primary d-block mx-auto mt-5"
              >
                Cookie-Einstellungen
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CookiesPolicy
